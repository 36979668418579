<template>
  <div class="report-component">
    <div class="info">
      <b-avatar :src="iconAlt" size="3rem" />
      <div class="data">
        <span class="user-name"
          >{{ data.user.first_name }} {{ data.user.last_name }}
        </span>
        <span class="date">{{ handelDateForm(data.created_at) }}</span>
      </div>
    </div>
    <p class="content">
      {{ data.text }}
    </p>
    <span class="stars">
      <star-icon color="yellow" v-for="index in data.star" :key="index" />
    </span>
  </div>
</template>
<script>
import starIcon from "@/components/productReviews/star";
import { BAvatar } from "bootstrap-vue";
export default {
  name: "reviewComponet",
  props: ["data"],
  components: {
    BAvatar,
    starIcon,
  },
  data() {
    return {
      iconAlt:
        "https://usuploads.s3.amazonaws.com/itlearn360/uploads/2018/12/dummy-profile-pic-300x300.jpg",
    };
  },
  methods: {
    handelDateForm(date) {
      const originalDate = new Date(date);
      const options = { year: "numeric", month: "short", day: "numeric" };
      const formattedDate = originalDate.toLocaleDateString("en-US", options);
      return formattedDate;
    },
  },
};
</script>
<style lang="scss">
@import "./index";
</style>
