<template>
  <b-progress
    :value="value"
    :max="total"
    :class="`my-2 ${color} Progress-bar`"
  ></b-progress>
</template>
<script>
import { BProgress } from "bootstrap-vue";
export default {
  props: ["value", "total", "color"],
  name: "progressBar",
  components: {
    BProgress,
  },
};
</script>
<style lang="scss">
@import "./index";
</style>
